import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState: any = {
  userInfo: null
};

// ==============================|| SLICE - MENU ||============================== //

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUserInfo(state, action) {
      state.userInfo = { ...action.payload };
    }
  }
});

export default user.reducer;

export const { setCurrentUserInfo } = user.actions;

export const getUser = (state: { user: { userInfo: any } }) => state.user.userInfo;
