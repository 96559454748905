import CircularProgress from '@mui/material/CircularProgress';
import Loader from 'components/Loader';
import { createContext, useCallback, useState } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { BlockUiProps } from 'types/common/blockui';
// initial state
const initialState: BlockUiProps = {
  handleLoad: (load: boolean) => {},
  open: false
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

export const BlockUiContext = createContext(initialState);

export const BlockUiProvider = ({ children }: { children: React.ReactElement }) => {
  const [open, setOpen] = useState(false);

  const addTodo = useCallback(
    (load: boolean) => {
      setOpen(load);
    },
    [open]
  );

  return (
    <BlockUiContext.Provider
      value={{
        handleLoad: addTodo,
        open
      }}
    >
      {open && <Loader />}
      <BlockUi tag="div" blocking={open} loader={<CircularProgress color="primary" size={45} />}>
        {children}
      </BlockUi>
    </BlockUiContext.Provider>
  );
};
