import { createSlice } from '@reduxjs/toolkit';
import { FormProps } from 'types/form';
// initial state
const initialState: FormProps = {
  schema: Object(),
  form_data: Object()
};

// ==============================|| SLICE - MENU ||============================== //

const form = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setSchema(state, action) {
      state.schema = { ...action.payload.schema };
    },
    setFormData(state, action) {
      state.form_data = { ...state.form_data, ...action.payload };
    }
  }
});

export default form.reducer;

export const { setSchema, setFormData } = form.actions;
