import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  company: null
};

export const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setLoginCompany: (state, action) => {
      state.company = action.payload;
    }
  }
});

export default company.reducer;
export const { setLoginCompany } = company.actions;
export const getLoginCompany = (state: { company: { company: any } }) => state.company.company;
