import { ReactElement, ReactNode } from 'react';

// scroll bar
import 'simplebar/src/simplebar.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import 'dayjs/locale/en';
import 'dayjs/locale/th';
// apex-chart
import 'styles/apex-chart.css';
import 'styles/react-table.css';

// next
import { NextPage } from 'next';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';

// third-party
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFns from '@tarzui/date-fns-be';
// import { format } from 'date-fns';
import { th } from 'date-fns/locale';
import { Provider as ReduxProvider } from 'react-redux';

// project import
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Snackbar from 'components/@extended/Snackbar';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import { BlockUiProvider } from 'contexts/BlockUiContext';
import { ConfigProvider } from 'contexts/ConfigContext';
import { store } from 'store';
import ThemeCustomization from 'themes';

// types
type LayoutProps = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface Props {
  Component: LayoutProps;
  pageProps: any;
}

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: AppProps & Props) {
  const getLayout = Component.getLayout ?? ((page: any) => page);

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <ThemeCustomization>
              <RTLLayout>
                <Locales>
                  <ScrollTop>
                    <SessionProvider session={pageProps.session} refetchInterval={0} refetchOnWindowFocus>
                      <>
                        <Snackbar />
                        <BlockUiProvider>{getLayout(<Component {...pageProps} />)}</BlockUiProvider>
                      </>
                    </SessionProvider>
                  </ScrollTop>
                </Locales>
              </RTLLayout>
            </ThemeCustomization>
          </ConfigProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
}
