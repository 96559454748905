// types
import { DefaultConfigProps } from 'types/config';

export const drawerWidth = 260;

// export const twitterColor = '#1DA1F2';
// export const facebookColor = '#3b5998';
// export const linkedInColor = '#0e76a8';

export const JWT_SECRET = `${process.env.JWT_SECRET}`;
export const DEFAULT_PATH = '/signup-wizard';
export const VERSION = `${process.env.NEXT_PUBLIC_APP_VERSION}`;
export const BASE_API = `/service`;
export const BASE_PATH = '';
export const KEY_MAP = 'AIzaSyBIJpnNBGHykhUEQHAvHrTa0G4eslhpkDg'; //new key

// export const KEY_MAP = 'AIzaSyBssNlbpmcfgZ0PVEdB0foNx7dZLCoQsBs';

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `'Prompt','Inter', sans-serif`,
  i18n: 'th',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'theme1',
  themeDirection: 'ltr'
};

export default config;
