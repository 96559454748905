// types
import { createSlice } from '@reduxjs/toolkit';
import { BreadcrumbProps } from 'types/breadcrumb';

// initial state
const initialState: BreadcrumbProps = {
  show: false,
  items: []
};

// ==============================|| SLICE - MENU ||============================== //

const breadcrumb = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumb(state, action) {
      state.items = action.payload.items;
      state.show = action.payload.show;
    }
  }
});

export default breadcrumb.reducer;

export const { setBreadcrumb } = breadcrumb.actions;
