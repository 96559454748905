// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import breadcrumb from './breadcrumb';
import form from './form';
import user from './user';
import company from './company';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  breadcrumb,
  form,
  user,
  company
});

export default reducers;
